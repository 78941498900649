<template>
  <!-- Vue template section -->
  <section v-if="layer">
    <b-card>
      <div>
        <h1>{{ layer.area }}</h1>
        <!-- Displaying location information -->
        <p>{{ layer.site ? `${layer.site}, ` : null }}{{ location }}</p>
      </div>
      <!-- Form wizard component for multi-step form -->
      <form-wizard
        ref="form-wizard"
        color="var(--primary)"
        error-color="var(--red)"
        shape="round"
        class="mb-3"
        style="margin-bottom: 30px"
        :title="null"
        :subtitle="null"
        :finish-button-text="$t('Global.Save')"
        :back-button-text="$t('Global.Previous')"
        :next-button-text="$t('Global.Next')"
        @on-complete="submit"
      >
        <!-- Tab content for lines configuration -->
        <tab-content
          :title="$tc('Management.Layers.Configure.Line', 0)"
          :before-change="() => validate('lines')"
        >
          <Lines
            ref="lines"
            :lines="layer.configuration.lines"
            :locked="locked"
          />
        </tab-content>

        <!-- Tab content for blocks configuration -->
        <tab-content
          :title="$tc('Management.Layers.Configure.Block', 0)"
          :before-change="() => validate('blocks')"
        >
          <Blocks
            ref="blocks"
            :blocks="layer.configuration.blocks"
            :locked="locked"
          />
        </tab-content>

        <!-- Tab content for layers configuration -->
        <tab-content
          :title="$tc('Management.Layers.Configure.Layer', 0)"
          :before-change="() => validate('layers')"
        >
          <Layers
            ref="layers"
            :layers="layer.configuration.layers"
            :locked="locked"
          />
        </tab-content>

        <!-- Tab content for groups configuration -->
        <tab-content
          :title="$tc('Management.Layers.Configure.Group', 0)"
          :before-change="() => validate('groups')"
        >
          <Groups
            ref="groups"
            :groups="layer.configuration.groups"
            :users="users"
            :locked="locked"
          />
        </tab-content>

        <!-- Tab content for configuration -->
        <tab-content
          :title="$tc('Management.Layers.Configure.Configuration', 0)"
          :before-change="() => validate('configure')"
        >
          <Configure
            ref="configure"
            :configuration="layer.configuration"
            :locked="locked"
          />
        </tab-content>

        <!-- Tab content for activation -->
        <tab-content
          :title="$tc('Management.Layers.Configure.Activate', 0)"
          :before-change="() => validate('activate')"
        >
          <Activate
            ref="activate"
            :configuration="layer.configuration"
            :locked="locked"
          />
        </tab-content>

        <!-- Custom buttons -->
        <template v-slot:custom-buttons-left>
          <div class="d-flex" style="flex: 1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              class="d-flex align-items-center"
              variant="outline-secondary"
              @click="$router.back()"
            >
              {{ $t('Global.Retreat') }}
            </b-button>
          </div>
        </template>
      </form-wizard>
    </b-card>
  </section>
</template>

<script>
import { BButton, BCard } from 'bootstrap-vue'
import moment from 'moment'
import { FormWizard, TabContent } from 'vue-form-wizard'

import '@core/scss/vue/libs/vue-wizard.scss'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import unsavedChanges from '@/utility/scripts/unsavedChanges'

import Activate from './components/configure/activate.vue'
import Blocks from './components/configure/blocks.vue'
import Configure from './components/configure/configure.vue'
import Groups from './components/configure/groups.vue'
import Layers from './components/configure/layers.vue'
import Lines from './components/configure/lines.vue'

export default {
  components: {
    BCard,
    BButton,

    Layers,
    Groups,
    Configure,
    Blocks,
    Activate,
    Lines,

    FormWizard,
    TabContent,
  },

  data: () => ({
    /**
     * @type {Object} The Layer to configure
     */
    layer: null,

    /**
     * @type {Array<Object>} The users to select from
     */
    users: [],

    /**
     * @type {Boolean} The route guard to prevent leaving the page
     */
    routeGuard: true,

    /**
     * @type {Boolean} The locked state of the layer
     */
    locked: false,
  }),

  computed: {
    /**
     * @type {String} The location of the layer
     */
    location() {
      if (!this.layer) return null
      const { street, houseNumber, zipCode, city } = this.layer
      const front = street && houseNumber ? `${street} ${houseNumber}, ` : ''
      const zipCodeString = zipCode ? `${zipCode} ` : ''
      return front + zipCodeString + (city || '')
    },
  },

  /**
   * @description Lifecycle hook to load the layer
   */
  created() {
    this.load()
    window.onbeforeunload = () => 'confirm'
  },

  /**
   * @description Lifecycle hook to remove the onbeforeunload event listener
   */
  destroyed() {
    window.onbeforeunload = null
  },

  /**
   * @description Lifecycle hook to check if the user wants to leave the page
   * @param {Object} to The route to navigate to
   * @param {Object} from The route to navigate from
   * @param {Function} next The next function to call
   */
  async beforeRouteUpdate(to, from, next) {
    const stillLeave = await this.wannaLeave()
    if (stillLeave) {
      window.onbeforeunload = null
      next()
    }
  },

  /**
   * @description Lifecycle hook to check if the user wants to leave the page
   * @param {Object} to The route to navigate to
   * @param {Object} from The route to navigate from
   * @param {Function} next The next function to call
   */
  async beforeRouteLeave(to, from, next) {
    const stillLeave = await this.wannaLeave()
    if (stillLeave) {
      window.onbeforeunload = null
      next()
    }
  },

  methods: {
    /**
     * @description Method to check if the user wants to leave the page
     * @returns {Boolean} The result of the check
     */
    async wannaLeave() {
      if (this.routeGuard === false) return true
      return await unsavedChanges(this)
    },

    /**
     * @description Method to submit the layer configuration
     * @returns {Promise<void>} The result of the submission
     */
    async submit() {
      const { configuration, id, area } = JSON.parse(JSON.stringify(this.layer))

      if (configuration.startDate) {
        configuration.startDate = moment(
          configuration.startDate,
          'DD.MM.YYYY',
        ).format('YYYY-MM-DD')
      }

      configuration.groups.forEach((group, i) => {
        if (group.shiftSupervisor) {
          configuration.groups[i].shiftSupervisor = group.shiftSupervisor.id
        }
        if (group.deputy) configuration.groups[i].deputy = group.deputy.id
        if (group.staffCap) {
          configuration.groups[i].staffCap = parseInt(group.staffCap, 10)
        }
      })

      try {
        await this.$axios.patch(`layers/${id}`, {
          configuration,
          active: typeof configuration.startDate === 'string',
        })

        this.routeGuard = false
        this.$alert.update(area)

        this.$nextTick(() => {
          this.$router.go(-1)
        })
      } catch (error) {
        console.error(error)
        this.$alert.error()
      }
    },

    /**
     * @description Method to validate a form
     * @param {String} ref The reference of the form to validate
     * @returns {Promise<Boolean>} The result of the validation
     */
    async validate(ref) {
      return this.$refs[ref].validate()
    },

    /**
     * @description Method to load the layer
     * @returns {Promise<void>} The result of the loading
     */
    async load() {
      try {
        const [layer, users] = await Promise.all([
          this.$axios.get(`layers/${this.$route.params.id}`),
          this.$axios.get('users'),
        ])

        const { startDate, groups, layers, blocks } = layer.data.configuration
        if (startDate) {
          layer.data.configuration.startDate = moment(
            startDate,
            'YYYY-MM-DD',
          ).format('DD.MM.YYYY')
          this.locked = true
        }

        if ([groups, layers, blocks].every(array => array.length !== 0)) {
          setTimeout(() => {
            this.$refs['form-wizard'].activateAll()
          }, 200)
        }

        this.layer = layer.data
        this.users = users.data
      } catch (error) {
        this.$alert.error()
      }
    },
  },
}
</script>
