var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"rules",attrs:{"tag":"div"}},[_c('b-form',{ref:"form",staticClass:"repeater-form",style:({ height: _vm.trHeight }),on:{"submit":function($event){$event.preventDefault();return _vm.repeateAgain($event)}}},[(_vm.blocks.length === 0)?_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$tc('Management.Layers.Configure.None', 1, { subject: _vm.$tc('Management.Layers.Configure.Block', 1), }))+" ")]):_vm._e(),_vm._l((_vm.blocks),function(block,index){return _c('b-row',{key:block.id,ref:"row",refInFor:true,attrs:{"id":block.id}},[_c('b-col',{staticClass:"margin-bottom",attrs:{"lg":"3","md":"3","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Management.Layers.Name') + '*',"label-for":("name-" + index)}},[_c('validation-provider',{attrs:{"name":((_vm.$t('Management.Layers.Name')) + " " + (index + 1)),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":("name-" + index),"placeholder":_vm.$t('Global.Write', {
                    subject: _vm.$t('Management.Layers.Name'),
                  }),"state":errors.length > 0 ? false : null,"autocomplete":"off"},model:{value:(block.name),callback:function ($$v) {_vm.$set(block, "name", $$v)},expression:"block.name"}}),_c('small',{staticClass:"text-danger",staticStyle:{"height":"15px","display":"block"}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"d-flex align-items-center justify-content-between flex-wrap",attrs:{"lg":"8","md":"8","sm":"11"}},_vm._l(([
              'Monday',
              'Tuesday',
              'Wednesday',
              'Thursday',
              'Friday',
              'Saturday',
              'Sunday' ]),function(key){return _c('b-form-checkbox',{key:key,staticStyle:{"margin-bottom":"7px"},attrs:{"value":true,"disabled":_vm.locked},on:{"change":function($event){_vm.clearOthers(key.toLowerCase(), index)}},model:{value:(block.week[key.toLowerCase()]),callback:function ($$v) {_vm.$set(block.week, key.toLowerCase(), $$v)},expression:"block.week[key.toLowerCase()]"}},[_vm._v(" "+_vm._s(_vm.$t(("Dates.Week.Long." + key)))+" ")])}),1),(!_vm.locked)?_c('b-col',{staticClass:"mb-50 d-flex align-items-center justify-content-center",attrs:{"lg":"1","md":"1","xl":"1"}},[_c('feather-icon',{staticClass:"mr-25 hover-red",staticStyle:{"margin-bottom":"0.5rem"},attrs:{"size":"20","icon":"Trash2Icon"},on:{"click":function($event){return _vm.removeItem(index)}}})],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')])],1)})],2)],1),(!_vm.locked)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"d-flex align-items-center justify-content-center",staticStyle:{"margin-top":"20px"},attrs:{"variant":"outline-primary"},on:{"click":_vm.repeateAgain}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon","size":"18"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$tc('Global.New'))+" "+_vm._s(_vm.$tc('Management.Layers.Configure.Block', 1))+" ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }