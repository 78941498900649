const dialog = async ({ $bvModal, $i18n, $createElement }) => {
  try {
    const messageVNode = $createElement('div', {
      class: ['py-1'],
      domProps: {
        innerHTML: $i18n.t('Global.UnsavedChangesLeave'),
        style: 'font-size: 14px',
      },
    })

    return await $bvModal.msgBoxConfirm([messageVNode], {
      title: $i18n.t('Global.Notice'),
      size: 'sm',
      okVariant: 'primary',
      cancelVariant: 'outline-secondary',
      okTitle: $i18n.t('Global.Leave'),
      cancelTitle: $i18n.t('Global.Retreat'),
      hideHeaderClose: false,
      centered: true,
      'body-class': 'p-1',
    })
  } catch {
    return false
  }
}

export default dialog
