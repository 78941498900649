<template>
  <div>
    <validation-observer ref="rules" tag="div">
      <b-form
        ref="form"
        :style="{ height: trHeight }"
        class="repeater-form"
        @submit.prevent="repeateAgain"
      >
        <p v-if="layers.length === 0" class="text-center">
          {{
            $tc('Management.Layers.Configure.None', 0, {
              subject: $tc('Management.Layers.Configure.Layer', 1),
            })
          }}
        </p>
        <b-row
          v-for="(layer, index) in layers"
          :id="layer.id"
          :key="layer.id"
          ref="row"
        >
          <b-col lg="3" md="3" sm="12" class="margin-bottom">
            <b-form-group
              :label="$t('Management.Layers.Name') + '*'"
              :label-for="`name-${index}`"
            >
              <validation-provider
                #default="{ errors }"
                :name="`${$t('Management.Layers.Name')} ${index + 1}`"
                rules="required"
              >
                <b-form-input
                  :id="`name-${index}`"
                  v-model="layer.name"
                  :placeholder="
                    $t('Global.Write', {
                      subject: $t('Management.Layers.Name'),
                    })
                  "
                  :state="errors.length > 0 ? false : null"
                  autocomplete="off"
                />
                <small class="text-danger" style="height: 15px; display: block">
                  {{ errors[0] }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col lg="2" md="2" sm="12">
            <validation-provider
              #default="{ errors }"
              :name="`${$t('Management.Layers.Configure.StartTime')} ${
                index + 1
              }`"
              :rules="{ required: true, regex: timeRegex }"
              class="mb-1"
            >
              <label class="d-block" :for="`start-time-${index}`">
                {{ $t('Management.Layers.Configure.StartTime') }}*
              </label>
              <b-input-group class="d-flex">
                <b-form-input
                  :id="`start-time-${index}`"
                  v-model="layer.startTime"
                  type="text"
                  autocomplete="off"
                  :disabled="locked"
                  :state="errors.length > 0 ? false : null"
                  placeholder="HH:mm"
                />
                <b-input-group-append>
                  <b-form-timepicker
                    v-model="layer.startTime"
                    v-bind="$t('Bootstrap.TimePicker')"
                    button-only
                    :disabled="locked"
                    :button-variant="
                      errors.length > 0 ? 'outline-danger' : 'outline-primary'
                    "
                    class="timepicker-height"
                    @input="timePickerInput('startTime', index)"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger" style="height: 15px; display: block">
                {{ errors[0] }}
              </small>
            </validation-provider>
          </b-col>

          <b-col lg="2" md="2" sm="12">
            <validation-provider
              #default="{ errors }"
              :name="`${$t('Management.Layers.Configure.EndTime')} ${
                index + 1
              }`"
              :rules="{ required: true, regex: timeRegex }"
              class="mb-1"
            >
              <label class="d-block" :for="`end-time-${index}`">
                {{ $t('Management.Layers.Configure.EndTime') }}*
              </label>
              <b-input-group class="d-flex">
                <b-form-input
                  :id="`end-time-${index}`"
                  v-model="layer.endTime"
                  type="text"
                  autocomplete="off"
                  :disabled="locked"
                  :state="errors.length > 0 ? false : null"
                  placeholder="HH:mm"
                />
                <b-input-group-append>
                  <b-form-timepicker
                    v-model="layer.endTime"
                    v-bind="$t('Bootstrap.TimePicker')"
                    button-only
                    :disabled="locked"
                    :button-variant="
                      errors.length > 0 ? 'outline-danger' : 'outline-primary'
                    "
                    class="timepicker-height"
                    @input="timePickerInput('endTime', index)"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger" style="height: 15px; display: block">
                {{ errors[0] }}
              </small>
            </validation-provider>
          </b-col>

          <b-col lg="2" md="2" sm="12">
            <validation-provider
              #default="{ errors }"
              :name="`${$t('Management.Layers.Configure.Break')} ${index + 1}`"
              :rules="{ integer: true, required: true }"
              class="mb-1"
            >
              <label class="d-block">
                {{ $t('Management.Layers.Configure.Break') }}*
              </label>
              <b-form-input
                v-model.number="layer.break"
                inputmode="numeric"
                type="number"
                :placeholder="`${$t('Management.Layers.Configure.Minutes')}`"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger" style="height: 15px; display: block">
                {{ errors[0] }}
              </small>
            </validation-provider>
          </b-col>

          <b-col lg="2" md="2" sm="12">
            <validation-provider
              #default="{ errors }"
              :name="`${$t('Management.Layers.Configure.Color')} ${index + 1}`"
              :rules="{ required: true, regex: colorRegex }"
              class="mb-1"
            >
              <label class="d-block" :for="`color-${index}`">
                {{ $t('Management.Layers.Configure.Color') }}*
              </label>
              <b-input-group class="d-flex">
                <b-form-input
                  :id="`color-${index}`"
                  v-model="layer.color"
                  type="text"
                  autocomplete="off"
                  :state="errors.length > 0 ? false : null"
                  placeholder="#D16F00"
                />
                <b-input-group-append>
                  <b-form-input
                    v-model="layer.color"
                    type="color"
                    class="input-color-button"
                    :state="errors.length > 0 ? false : null"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger" style="height: 15px; display: block">
                {{ errors[0] }}
              </small>
            </validation-provider>
          </b-col>

          <b-col
            v-if="!locked"
            lg="1"
            md="1"
            xl="1"
            class="mb-50 d-flex align-items-center justify-content-center"
          >
            <feather-icon
              size="20"
              icon="Trash2Icon"
              class="mr-25 hover-red"
              style="margin-bottom: 0.5rem"
              @click="removeItem(index)"
            />
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-button
      v-if="!locked"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      style="margin-top: 20px"
      variant="outline-primary"
      class="d-flex align-items-center justify-content-center"
      @click="repeateAgain"
    >
      <feather-icon icon="PlusIcon" class="mr-25" size="18" />
      <span>
        {{ $tc('Global.New', 0) }}
        {{ $tc('Management.Layers.Configure.Layer', 1) }}</span>
    </b-button>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BFormTimepicker,
  BInputGroup,
  BInputGroupAppend,
  BForm,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, regex } from '@validations'
import { timeRegex, colorRegex } from '@/utility/regex'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTimepicker,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupAppend,
    BForm,
  },

  mixins: [heightTransition],

  props: {
    layers: {
      type: Array,
      required: true,
    },
    locked: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    required,
    regex,
    timeRegex,
    colorRegex,
    nextId: 0,
  }),

  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },

  methods: {
    async validate() {
      if (this.layers.length === 0) {
        this.$alert.invalid(
          this.$tc('Management.Layers.Configure.MinOne', 0, {
            subject: this.$tc('Management.Layers.Configure.Layer', 1),
          }),
        )
        return false
      }

      const valid = await this.$refs.rules.validate()
      this.initTrHeight()
      if (!valid) return false

      const timespans = this.validateTimeSpans()
      if (!timespans) {
        this.$alert.invalid(
          this.$t('Management.Layers.Configure.TimespansOverlap'),
        )
      }
      return timespans
    },

    convertTimeStringToMilliseconds(string) {
      const [hourString, minutesString] = string.split(':', 2)
      return (
        (parseInt(hourString, 10) * 60 + parseInt(minutesString, 10)) *
        60 *
        1000
      )
    },

    convertToTimestamps(layers) {
      return layers.map(x => {
        const startTime = this.convertTimeStringToMilliseconds(x.startTime)
        let endTime = this.convertTimeStringToMilliseconds(x.endTime)

        if (startTime > endTime) {
          endTime += 86400000 // Add one Day
        }

        return {
          startTime,
          endTime,
        }
      })
    },

    validateTimeSpans() {
      const timestamps = this.convertToTimestamps(this.layers)

      const min = Math.min(...timestamps.map(x => x.startTime))
      const max = Math.max(...timestamps.map(x => x.endTime))

      if (max - min > 86400000) {
        return false
      }

      for (let i = 0; i < timestamps.length - 1; i += 1) {
        for (let j = i + 1; j < timestamps.length; j += 1) {
          if (
            timestamps[i].endTime > timestamps[j].startTime &&
            timestamps[j].endTime > timestamps[i].startTime
          ) {
            return false
          }
        }
      }
      return true
    },

    timePickerInput(key, index) {
      if (this.layers[index][key] === null) return
      this.layers[index][key] = this.layers[index][key].slice(0, 5)
    },

    repeateAgain() {
      this.layers.push({
        id: (this.nextId += 1),
        name: null,
        startTime: null,
        endTime: null,
        color: null,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.layers.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        const { scrollHeight } = this.$refs.form
        if (scrollHeight === 0 && this.layers.length !== 0) {
          this.trSetHeight(this.layers.length * 103)
          return
        }
        this.trSetHeight(scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss">
.repeater-form {
  transition: 0.35s height;
}

.margin-bottom {
  margin-bottom: -7px;
}

.timepicker-height {
  > button.btn {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    &[disabled='disabled'] {
      background-color: #efefef !important;
      border-color: #d8d6de !important;
      color: #6e6b7b !important;
    }
  }
}
</style>
