<template>
  <b-row>
    <b-col lg="4" md="4" sm="12">
      <h5>{{ $t('Management.Layers.Configure.BlockOrder') }}</h5>
      <draggable
        :disabled="locked"
        :list="configuration.blocks"
        tag="ul"
        group="blocks"
        class="list-group list-group-flush cursor-move"
      >
        <b-list-group-item
          v-for="(block, index) in configuration.blocks"
          :key="index"
          tag="li"
        >
          <div class="d-flex">
            {{ block.name }}
          </div>
        </b-list-group-item>
      </draggable>
    </b-col>
    <b-col lg="4" md="4" sm="12">
      <h5>{{ $t('Management.Layers.Configure.LayerOrder') }}</h5>
      <draggable
        :disabled="locked"
        :list="configuration.layers"
        tag="ul"
        group="layers"
        class="list-group list-group-flush cursor-move"
      >
        <b-list-group-item
          v-for="(layer, index) in configuration.layers"
          :key="index"
          tag="li"
        >
          <div class="d-flex">
            {{ layer.name }}
          </div>
        </b-list-group-item>
      </draggable>
    </b-col>
    <b-col lg="4" md="4" sm="12">
      <h5>{{ $t('Management.Layers.Configure.GroupOrder') }}</h5>
      <draggable
        :disabled="locked"
        :list="configuration.groups"
        tag="ul"
        group="groups"
        class="list-group list-group-flush cursor-move"
      >
        <b-list-group-item
          v-for="(group, index) in configuration.groups"
          :key="index"
          tag="li"
        >
          <div class="d-flex">
            {{ group.name }}
          </div>
        </b-list-group-item>
      </draggable>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BListGroupItem } from 'bootstrap-vue'
import draggable from 'vuedraggable'

export default {
  components: {
    BRow,
    BCol,
    BListGroupItem,
    draggable,
  },

  props: {
    configuration: {
      type: Object,
      required: true,
    },
    locked: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    async validate() {
      return true
    },
  },
}
</script>

<style lang="scss">
.timepicker-height {
  > button.btn {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}
</style>
