var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"rules",attrs:{"tag":"div"}},[_c('b-form',{ref:"form",staticClass:"repeater-form",style:({ height: _vm.trHeight }),on:{"submit":function($event){$event.preventDefault();return _vm.repeateAgain($event)}}},[(_vm.groups.length === 0)?_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$tc('Management.Layers.Configure.None', 0, { subject: _vm.$tc('Management.Layers.Configure.Group', 1), }))+" ")]):_vm._e(),_vm._l((_vm.groups),function(group,index){return _c('b-row',{key:group.id,ref:"row",refInFor:true,attrs:{"id":group.id}},[_c('b-col',{staticClass:"margin-bottom",attrs:{"lg":"3","md":"3","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Management.Layers.Name') + '*',"label-for":("name-" + index)}},[_c('validation-provider',{attrs:{"name":((_vm.$t('Management.Layers.Name')) + " " + (index + 1)),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":("name-" + index),"placeholder":_vm.$t('Global.Write', {
                    subject: _vm.$t('Management.Layers.Name'),
                  }),"state":errors.length > 0 ? false : null,"autocomplete":"off"},model:{value:(group.name),callback:function ($$v) {_vm.$set(group, "name", $$v)},expression:"group.name"}}),_c('small',{staticClass:"text-danger",staticStyle:{"height":"15px","display":"block"}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"2","md":"2","sm":"12"}},[_c('validation-provider',{staticClass:"mb-1",attrs:{"name":((_vm.$t('Management.Layers.Configure.Color')) + " " + (index + 1)),"rules":{ required: true, regex: _vm.colorRegex }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('label',{staticClass:"d-block",attrs:{"for":("color-" + index)}},[_vm._v(" "+_vm._s(_vm.$t('Management.Layers.Configure.Color'))+"* ")]),_c('b-input-group',{staticClass:"d-flex"},[_c('b-form-input',{attrs:{"id":("color-" + index),"type":"text","autocomplete":"off","state":errors.length > 0 ? false : null,"placeholder":"#FF9214"},model:{value:(group.color),callback:function ($$v) {_vm.$set(group, "color", $$v)},expression:"group.color"}}),_c('b-input-group-append',[_c('b-form-input',{staticClass:"input-color-button",attrs:{"type":"color","state":errors.length > 0 ? false : null},model:{value:(group.color),callback:function ($$v) {_vm.$set(group, "color", $$v)},expression:"group.color"}})],1)],1),_c('small',{staticClass:"text-danger",staticStyle:{"height":"15px","display":"block"}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-col',{attrs:{"lg":"3","md":"3","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Management.Layers.Configure.ShiftSupervisor'),"label-for":"shiftSupervisor"}},[_c('v-select',{attrs:{"id":"shiftSupervisor","label":"id","placeholder":_vm.$t('Global.Select', {
                  subject: _vm.$t('Management.Layers.Configure.ShiftSupervisor'),
                }),"options":_vm.shiftSupervisors,"selectable":function (value) {
                  if (value == null || group.deputy == null) { return true }
                  return value.id !== group.deputy.id
                }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                var firstname = ref.firstname;
                var lastname = ref.lastname;
return [_c('div',[_vm._v(_vm._s(firstname)+" "+_vm._s(lastname))])]}},{key:"selected-option",fn:function(ref){
                var firstname = ref.firstname;
                var lastname = ref.lastname;
return [_c('div',[_vm._v(_vm._s(firstname)+" "+_vm._s(lastname))])]}}],null,true),model:{value:(group.shiftSupervisor),callback:function ($$v) {_vm.$set(group, "shiftSupervisor", $$v)},expression:"group.shiftSupervisor"}})],1)],1),_c('b-col',{attrs:{"lg":"3","md":"3","sm":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Management.Layers.Configure.Deputy'),"label-for":"deputy"}},[_c('v-select',{attrs:{"id":"deputy","label":"id","placeholder":_vm.$t('Global.Select', {
                  subject: _vm.$t('Management.Layers.Configure.Deputy'),
                }),"options":_vm.shiftSupervisors,"selectable":function (value) {
                  if (value == null || group.shiftSupervisor == null)
                    { return true }
                  return value.id !== group.shiftSupervisor.id
                }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                var firstname = ref.firstname;
                var lastname = ref.lastname;
return [_c('div',[_vm._v(_vm._s(firstname)+" "+_vm._s(lastname))])]}},{key:"selected-option",fn:function(ref){
                var firstname = ref.firstname;
                var lastname = ref.lastname;
return [_c('div',[_vm._v(_vm._s(firstname)+" "+_vm._s(lastname))])]}}],null,true),model:{value:(group.deputy),callback:function ($$v) {_vm.$set(group, "deputy", $$v)},expression:"group.deputy"}})],1)],1),(!_vm.locked)?_c('b-col',{staticClass:"mb-50 d-flex align-items-center justify-content-center",attrs:{"lg":"1","md":"1","xl":"1"}},[_c('feather-icon',{staticClass:"mr-25 hover-red",staticStyle:{"margin-bottom":"0.5rem"},attrs:{"size":"20","icon":"Trash2Icon"},on:{"click":function($event){return _vm.removeItem(index)}}})],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')])],1)})],2)],1),(!_vm.locked)?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"d-flex align-items-center justify-content-center",staticStyle:{"margin-top":"20px"},attrs:{"variant":"outline-primary"},on:{"click":_vm.repeateAgain}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon","size":"18"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$tc('Global.New', 0))+" "+_vm._s(_vm.$tc('Management.Layers.Configure.Group', 1))+" ")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }