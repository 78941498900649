<template>
  <div>
    <validation-observer ref="rules" tag="div">
      <b-form
        ref="form"
        :style="{ height: trHeight }"
        class="repeater-form"
        @submit.prevent="repeateAgain"
      >
        <p v-if="groups.length === 0" class="text-center">
          {{
            $tc('Management.Layers.Configure.None', 0, {
              subject: $tc('Management.Layers.Configure.Group', 1),
            })
          }}
        </p>
        <b-row
          v-for="(group, index) in groups"
          :id="group.id"
          :key="group.id"
          ref="row"
        >
          <!-- Item Name -->
          <b-col lg="3" md="3" sm="12" class="margin-bottom">
            <b-form-group
              :label="$t('Management.Layers.Name') + '*'"
              :label-for="`name-${index}`"
            >
              <validation-provider
                #default="{ errors }"
                :name="`${$t('Management.Layers.Name')} ${index + 1}`"
                rules="required"
              >
                <b-form-input
                  :id="`name-${index}`"
                  v-model="group.name"
                  :placeholder="
                    $t('Global.Write', {
                      subject: $t('Management.Layers.Name'),
                    })
                  "
                  :state="errors.length > 0 ? false : null"
                  autocomplete="off"
                />
                <small class="text-danger" style="height: 15px; display: block">
                  {{ errors[0] }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col lg="2" md="2" sm="12">
            <validation-provider
              #default="{ errors }"
              :name="`${$t('Management.Layers.Configure.Color')} ${index + 1}`"
              :rules="{ required: true, regex: colorRegex }"
              class="mb-1"
            >
              <label class="d-block" :for="`color-${index}`">
                {{ $t('Management.Layers.Configure.Color') }}*
              </label>
              <b-input-group class="d-flex">
                <b-form-input
                  :id="`color-${index}`"
                  v-model="group.color"
                  type="text"
                  autocomplete="off"
                  :state="errors.length > 0 ? false : null"
                  placeholder="#FF9214"
                />
                <b-input-group-append>
                  <b-form-input
                    v-model="group.color"
                    type="color"
                    class="input-color-button"
                    :state="errors.length > 0 ? false : null"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger" style="height: 15px; display: block">
                {{ errors[0] }}
              </small>
            </validation-provider>
          </b-col>

          <b-col lg="3" md="3" sm="12">
            <b-form-group
              :label="$t('Management.Layers.Configure.ShiftSupervisor')"
              label-for="shiftSupervisor"
            >
              <v-select
                id="shiftSupervisor"
                v-model="group.shiftSupervisor"
                label="id"
                :placeholder="
                  $t('Global.Select', {
                    subject: $t('Management.Layers.Configure.ShiftSupervisor'),
                  })
                "
                :options="shiftSupervisors"
                :selectable="
                  value => {
                    if (value == null || group.deputy == null) return true
                    return value.id !== group.deputy.id
                  }
                "
              >
                <template #option="{ firstname, lastname }">
                  <div>{{ firstname }} {{ lastname }}</div>
                </template>
                <template #selected-option="{ firstname, lastname }">
                  <div>{{ firstname }} {{ lastname }}</div>
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <b-col lg="3" md="3" sm="12">
            <b-form-group
              :label="$t('Management.Layers.Configure.Deputy')"
              label-for="deputy"
            >
              <v-select
                id="deputy"
                v-model="group.deputy"
                label="id"
                :placeholder="
                  $t('Global.Select', {
                    subject: $t('Management.Layers.Configure.Deputy'),
                  })
                "
                :options="shiftSupervisors"
                :selectable="
                  value => {
                    if (value == null || group.shiftSupervisor == null)
                      return true
                    return value.id !== group.shiftSupervisor.id
                  }
                "
              >
                <template #option="{ firstname, lastname }">
                  <div>{{ firstname }} {{ lastname }}</div>
                </template>
                <template #selected-option="{ firstname, lastname }">
                  <div>{{ firstname }} {{ lastname }}</div>
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <b-col
            v-if="!locked"
            lg="1"
            md="1"
            xl="1"
            class="mb-50 d-flex align-items-center justify-content-center"
          >
            <feather-icon
              size="20"
              icon="Trash2Icon"
              class="mr-25 hover-red"
              style="margin-bottom: 0.5rem"
              @click="removeItem(index)"
            />
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-button
      v-if="!locked"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      style="margin-top: 20px"
      variant="outline-primary"
      class="d-flex align-items-center justify-content-center"
      @click="repeateAgain"
    >
      <feather-icon icon="PlusIcon" class="mr-25" size="18" />
      <span>
        {{ $tc('Global.New', 0) }}
        {{ $tc('Management.Layers.Configure.Group', 1) }}
      </span>
    </b-button>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BInputGroup,
  BInputGroupAppend,
  BForm,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, regex } from '@validations'
import vSelect from 'vue-select'
import { RolesEnum } from '@/data/enums'
import { colorRegex } from '@/utility/regex'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    BForm,
  },

  mixins: [heightTransition],

  props: {
    groups: {
      type: Array,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    locked: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    required,
    regex,
    colorRegex,
    nextId: 0,
  }),

  computed: {
    shiftSupervisors() {
      return this.users.filter(x => x.role === RolesEnum.ShiftSupervisor)
    },
  },

  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },

  methods: {
    async validate() {
      if (this.groups.length === 0) {
        this.$alert.invalid(
          this.$tc('Management.Layers.Configure.MinOne', 0, {
            subject: this.$tc('Management.Layers.Configure.Group', 1),
          }),
        )
        return false
      }

      const valid = this.$refs.rules.validate()

      this.initTrHeight()

      return valid
    },

    repeateAgain() {
      this.groups.push({
        id: (this.nextId += 1),
        name: null,
        color: null,
        shiftSupervisor: null,
        deputy: null,
        staffCap: null,
        extendedAuth: false,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.groups.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        const { scrollHeight } = this.$refs.form
        if (scrollHeight === 0 && this.groups.length !== 0) {
          this.trSetHeight(this.groups.length * 103)
          return
        }
        this.trSetHeight(scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.repeater-form {
  transition: 0.35s height;
}

.margin-bottom {
  margin-bottom: -7px;
}

input.input-color-button {
  width: 3rem;
  margin-left: -1px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;

  &::-webkit-color-swatch {
    border-radius: calc(0.357rem / 2);
    border: none;
  }
}
</style>
