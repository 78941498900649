<template>
  <div>
    <validation-observer ref="rules" tag="div">
      <b-form
        ref="form"
        :style="{ height: trHeight }"
        class="repeater-form"
        @submit.prevent="repeateAgain"
      >
        <p v-if="lines.length === 0" class="text-center">
          {{
            $tc('Management.Layers.Configure.None', 1, {
              subject: $tc('Management.Layers.Configure.Line', 1),
            })
          }}
        </p>
        <b-row
          v-for="(line, index) in lines"
          :id="line.id"
          :key="line.id"
          ref="row"
        >
          <!-- Item Name -->
          <b-col lg="3" md="3" sm="12" class="margin-bottom">
            <b-form-group
              :label="$t('Management.Layers.Name') + '*'"
              :label-for="`name-${index}`"
            >
              <validation-provider
                #default="{ errors }"
                :name="`${$t('Management.Layers.Name')} ${index + 1}`"
                rules="required"
              >
                <b-form-input
                  :id="`name-${index}`"
                  v-model="line.name"
                  :placeholder="
                    $t('Global.Write', {
                      subject: $t('Management.Layers.Name'),
                    })
                  "
                  :state="errors.length > 0 ? false : null"
                  autocomplete="off"
                />
                <small class="text-danger" style="height: 15px; display: block">
                  {{ errors[0] }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            v-if="!locked"
            lg="1"
            md="1"
            xl="1"
            class="mb-50 d-flex align-items-center justify-content-center"
          >
            <feather-icon
              size="20"
              icon="Trash2Icon"
              class="mr-25 hover-red"
              style="margin-bottom: 0.5rem"
              @click="removeItem(index)"
            />
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-button
      v-if="!locked"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="outline-primary"
      style="margin-top: 20px"
      class="d-flex align-items-center justify-content-center"
      @click="repeateAgain"
    >
      <feather-icon icon="PlusIcon" class="mr-25" size="18" />
      <span>
        {{ $tc('Global.New', 0) }}
        {{ $tc('Management.Layers.Configure.Line') }}
      </span>
    </b-button>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BForm,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BForm,
  },

  mixins: [heightTransition],

  props: {
    lines: {
      type: Array,
      required: true,
    },
    locked: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    required,
    nextId: 0,
  }),

  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },

  methods: {
    async validate() {
      if (this.lines.length === 0) {
        this.$alert.invalid(
          this.$tc('Management.Layers.Configure.MinOne', 1, {
            subject: this.$tc('Management.Layers.Configure.Line', 1),
          }),
        )
        return false
      }

      const valid = this.$refs.rules.validate()

      this.initTrHeight()

      return valid
    },

    repeateAgain() {
      this.lines.push({
        id: (this.nextId += 1),
        name: null,
      })
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },

    removeItem(index) {
      this.lines.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },

    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        const { scrollHeight } = this.$refs.form
        if (scrollHeight === 0 && this.lines.length !== 0) {
          this.trSetHeight(this.lines.length * 103)
          return
        }
        this.trSetHeight(scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
.margin-bottom {
  margin-bottom: -7px;
}
</style>
