<template>
  <validation-observer ref="rules" tag="form" @submit.prevent="validate">
    <b-row>
      <b-col lg="6" md="6" sm="12">
        <b-alert v-if="!locked" variant="danger" show>
          <div class="alert-body">
            {{ $t('Management.Layers.Configure.ActivationText') }}
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="6" md="12" sm="12">
        <b-row class="mb-3">
          <b-col lg="4" md="4" sm="12">
            <validation-provider
              #default="{ errors }"
              :name="$t('Management.Layers.Configure.StartDate')"
              :rules="{
                regex: dateRegex,
              }"
            >
              <label class="d-block" for="start-date">
                {{ $t('Management.Layers.Configure.StartDate') }}*
              </label>
              <b-input-group>
                <b-form-input
                  id="start-date"
                  ref="start-date"
                  v-model="configuration.startDate"
                  placeholder="DD.MM.JJJJ"
                  autocomplete="off"
                  :disabled="locked"
                  :state="errors.length > 0 ? false : null"
                />
                <b-input-group-append>
                  <b-form-datepicker
                    v-model="datePicker"
                    v-bind="$t('Bootstrap.DatePicker')"
                    button-only
                    locale="de-DE"
                    :disabled="locked"
                    :start-weekday="1"
                    :button-variant="
                      errors.length > 0 ? 'outline-danger' : 'outline-primary'
                    "
                    class="timepicker-height"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col lg="3" md="3" sm="12">
            <label class="d-block" for="order-attachment">
              {{ $t('Management.Layers.Configure.OrderAttachment') }}
            </label>
            <b-form-checkbox
              id="order-attachment"
              v-model="configuration.attachOrder"
              switch
              class="switch-spacing"
            />
          </b-col>
          <b-col lg="5" md="5" sm="12">
            <label class="d-block" for="can-manage-past-attendance">
              {{ $t('Management.Layers.Configure.CanManagePastAttendance') }}
            </label>
            <b-form-checkbox
              id="can-manage-past-attendance"
              v-model="configuration.canManagePastAttendance"
              switch
              class="switch-spacing"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="4" md="4" sm="12">
            <div class="d-flex align-items-center space-bottom">
              <span>{{ $t('Management.Layers.Configure.ExtendedAuth') }}</span>
              <span
                class="text-primary select-everything"
                @click="selectEverythingExtendedAuth"
              >
                {{ $t('Management.Layers.Configure.SelectEverything') }}
              </span>
            </div>
            <div
              v-for="(group, i) in configuration.groups"
              :key="`${i}`"
              class="space-bottom"
            >
              <b-form-checkbox
                v-model="group.extendedAuth"
                :value="true"
                class="custom-control-primary"
              >
                {{ group.name }}
              </b-form-checkbox>
            </div>
          </b-col>
          <b-col lg="4" md="4" sm="12">
            <div class="d-flex align-items-center space-bottom">
              <span>{{ $t('Management.Layers.Configure.StaffCap') }}</span>
              <span
                v-if="!locked"
                class="text-primary select-everything"
                @click="selectEverythingStaffCap"
              >
                {{ $t('Management.Layers.Configure.SelectEverything') }}
              </span>
            </div>
            <div
              v-for="(group, i) in configuration.groups"
              :key="`${i}`"
              class="space-bottom d-flex align-items-center"
            >
              <b-form-checkbox
                :checked="group.staffCap != null"
                :value="true"
                class="custom-control-primary"
                @change="value => handleChangeStaffCap(value, i)"
              >
                {{ group.name }}
              </b-form-checkbox>
              <validation-provider
                v-if="group.staffCap !== null"
                #default="{ errors }"
                :name="`${$t('Management.Layers.Configure.StaffCap')} ${i}`"
                :rules="{ integer: true }"
              >
                <b-form-input
                  v-model="configuration.groups[i].staffCap"
                  placeholder="s"
                  size="sm"
                  class="ml-1"
                  autocomplete="off"
                  :state="errors.length > 0 ? false : null"
                />
              </validation-provider>
            </div>
          </b-col>
        </b-row>
      </b-col>

      <b-col lg="4" md="12" sm="12">
        <div class="d-flex align-items-center space-bottom">
          <span>{{ $t('Management.Layers.Configure.SurCharge') }}</span>
          <span
            class="text-primary select-everything"
            @click="selectEverythingInSurCharge"
          >
            {{ $t('Management.Layers.Configure.SelectEverything') }}
          </span>
        </div>
        <div
          v-for="(day, i) in Object.keys(SurCharge)"
          :key="`${i}`"
          class="sur-charge"
        >
          <b-form-checkbox
            v-model="selectedOptions"
            style="width: 185px; display: flex"
            :value="day"
            class="custom-control-primary"
          >
            {{ $t(`Management.Layers.${day}`) }}
          </b-form-checkbox>
          <div>
            <validation-provider
              v-if="selectedOptions.includes(day)"
              #default="{ errors }"
              :name="`${$t('Management.Layers.Configure.SurCharge')} ${i}`"
              :rules="{ integer: true }"
            >
              <b-form-input
                v-model.number="configuration.surCharge[day]"
                type="number"
                placeholder="%"
                size="sm"
                style="max-width: 100px"
                :state="errors.length > 0 ? false : null"
              />
            </validation-provider>
          </div>
          <validation-provider
            v-if="selectedOptions.includes(day)"
            #default="{ errors }"
            :name="`${$t('Management.Layers.Configure.SurCharge')} ${i}`"
            :rules="{ integer: true }"
            tag="div"
            class="d-flex"
          >
            <v-select
              v-if="
                selectedOptions.includes(day) &&
                (day === 'lateShift' || day === 'nightShift')
              "
              class="select-shift-index"
              :options="getList()"
              :placeholder="
                getActiveLayer(configuration.surCharge[day + 'Index'])
              "
              :clearable="false"
              :state="errors.length > 0 ? false : null"
              @input="
                value => {
                  configuration.surCharge[day + 'Index'] = value.value
                }
              "
            />
          </validation-provider>
        </div>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import { dateRegex } from '@/utility/regex'
import {
  BAlert,
  BCol,
  BFormCheckbox,
  BFormDatepicker,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BRow,
} from 'bootstrap-vue'
import moment from 'moment'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import SurCharge from '../../enums'

export default {
  components: {
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BFormInput,
    BFormDatepicker,
    BInputGroupAppend,
    BFormCheckbox,
    vSelect,
    BAlert,
  },

  props: {
    configuration: {
      type: Object,
      required: true,
    },
    locked: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    dateRegex,
    SurCharge,
    selectedOptions: [],
  }),

  computed: {
    datePicker: {
      get() {
        return this.configuration.startDate
      },
      set(value) {
        this.configuration.startDate = moment(value, 'YYYY-MM-DD').format(
          'DD.MM.YYYY',
        )
        this.$refs['start-date'].focus()
      },
    },
  },

  watch: {
    selectedOptions(arr) {
      Object.keys(this.configuration.surCharge).forEach(key => {
        if (
          !arr.includes(key) &&
          key !== 'nightShiftIndex' &&
          key !== 'lateShiftIndex'
        ) {
          delete this.configuration.surCharge[key]
          if (key === 'lateShift') {
            this.configuration.surCharge.lateShiftIndex = undefined
          }
          if (key === 'nightShift') {
            this.configuration.surCharge.nightShiftIndex = undefined
          }
        }
      })

      arr.forEach(elem => {
        if (!(elem in this.configuration.surCharge)) {
          this.configuration.surCharge[elem] = 0
        }
      })
    },
  },

  created() {
    if (this.configuration.surCharge === undefined) {
      this.configuration.surCharge = {}
      return
    }
    Object.keys(this.configuration.surCharge).forEach(key => {
      this.selectedOptions.push(key)
    })
  },

  methods: {
    selectEverythingInSurCharge() {
      Object.keys(SurCharge).forEach(key => {
        if (!this.selectedOptions.includes(key)) this.selectedOptions.push(key)
      })
    },

    getActiveLayer(index) {
      if (index === undefined) return ''
      return this.configuration.layers[index].name
    },

    getList() {
      const shiftList = []
      this.configuration.layers.forEach((layer, index) => {
        shiftList.push({ label: layer.name, value: index })
      })
      return shiftList
    },

    async validate() {
      return this.$refs.rules.validate()
    },

    handleChangeStaffCap(value, index) {
      this.configuration.groups[index].staffCap = value ? 0 : null
    },

    selectEverythingExtendedAuth() {
      this.configuration.groups.forEach((group, index) => {
        this.configuration.groups[index].extendedAuth = true
      })
    },

    selectEverythingCanManagePastAttendance() {
      this.configuration.groups.forEach((_, index) => {
        this.configuration.groups[index].canManagePastAttendance = true
        console.count('selectEverythingCanManagePastAttendance')
      })
    },

    selectEverythingStaffCap() {
      this.configuration.groups.forEach((_, index) => {
        this.configuration.groups[index].staffCap = 0
      })
    },
  },
}
</script>

<style lang="scss">
.timepicker-height {
  > button.btn {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    &[disabled='disabled'] {
      background-color: #efefef !important;
      border-color: #d8d6de !important;
      color: #6e6b7b !important;
    }
  }
}

.select-everything {
  cursor: pointer;
  font-size: 12px;
  margin-left: 0.5rem;
  user-select: none;
  &:active {
    opacity: 0.8;
  }
}

.space-bottom {
  margin-bottom: 0.5rem;
}

.switch-spacing {
  margin-top: 0.75rem;
}

.sur-charge {
  display: flex;
  min-height: 30px;
  margin-bottom: 0.5rem;
  align-items: center;
}
</style>

<style lang="scss">
.select-shift-index {
  width: 150px;
  flex: 1;
  margin-left: 10px;
  min-height: 30px;
  display: flex;

  .vs__dropdown-toggle {
    padding: 0 !important;
    width: 150px;

    .vs__search,
    .vs__selected {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }

    font-size: 0.857rem !important;

    .vs__actions {
      padding-top: 0 !important;
    }
  }
}
</style>
